import { render, staticRenderFns } from "./PaDeptAdd.vue?vue&type=template&id=716a7ee9&scoped=true"
import script from "./PaDeptAdd.vue?vue&type=script&lang=js"
export * from "./PaDeptAdd.vue?vue&type=script&lang=js"
import style0 from "./PaDeptAdd.vue?vue&type=style&index=0&id=716a7ee9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716a7ee9",
  null
  
)

export default component.exports